export default function getCurrentDate() {
	const date = new Date()

	let day = date.getDate()
	let month = date.getMonth() + 1
	let year = date.getFullYear()

	if(day < 10){
		day = '0'+day;
	}

	// This arrangement can be altered based on how we want the date's format to appear.
	let currentDate = `${month}-${day}-${year}`

	$('.output-date').html(currentDate)
}
